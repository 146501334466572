import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
  {
    path: "/404",
    component: () => import("@/views/error/404.vue"),
  },
  {
    path: "/",
    name: "yin-container",
    component: () => import("@/views/YinContainer.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/enterprise-detail/:id",
        name: "enterprise-detail",
        component: () => import("@/views/enterpriseDetail.vue"),
      },
       {
        path: "/position-detail/:id",
        name: "position-detail",
        component: () => import("@/views/positionDetail.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/user.vue"),
      },
      {
        path: "/agreement",
        name: "agreement",
        component: () => import("@/views/agreement.vue"),
      },
      {
        path: "/setting",
        name: "setting",
        meta: {
          requireAuth: true,
        },
        component: () => import("@/views/setting/Setting.vue"),
        children: [
          {
            path: "/setting/PersonalData",
            name: "personalData",
            meta: {
              requireAuth: true,
            },
            component: () => import("@/views/setting/PersonalData.vue"),
          }
        ]
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
