// 登录规则
const validatePhone = (rule, value, callback) => {
  if (!value) {
    return callback(new Error("手机号不能为空"));
  // } else if(!/^1[3-9]\d{9}$/.test(value)){
  //   callback(new Error("手机号格式有误"));
  } else {
    callback();
  }
};

export const validatePassword = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("密码不能为空"));
  } else {
    callback();
  }
};

export const validateVerificationCode = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("验证码不能为空"));
  } else  if (value.length!=6) {
    callback(new Error("验证码格式有误"));
  } else {
    callback();
  }
};

export const validateCheckbox = (rule, value, callback) => {
  if (value===true) {
    callback();
  }else {
     callback(new Error("请勾选"));
  }
};

export const SignInRules = {
  phone: [{ validator: validatePhone, trigger: "blur", min: 11 }],
  code: [{ validator: validateVerificationCode, trigger: "blur", min: 6, max: 6 }],
  isChecked: [{ validator: validateCheckbox, trigger: "change" }],
};

// 注册规则
export const SignUpRules = {
  username: [{ required: true, trigger: "blur", min: 3 }],
  password: [{ required: true, trigger: "blur", min: 3 }],
  sex: [{ required: true, message: "请选择性别", trigger: "change" }],
  phoneNum: [{ essage: "请选择日期", trigger: "blur" }],
  email: [
    { message: "请输入邮箱地址", trigger: "blur" },
    {
      type: "email",
      message: "请输入正确的邮箱地址",
      trigger: ["blur", "change"],
    },
  ],
  birth: [{ required: true, type: "date", message: "请选择日期", trigger: "change" }],
  introduction: [{ message: "请输入介绍", trigger: "blur" }],
  location: [{ message: "请输入地区", trigger: "change" }],
};
