<template>
  <router-view  v-if="token"/>
</template>

<script lang="ts" setup>
import { HttpManager } from "@/api";
// import mixin from "@/mixins/mixin";
import { Storage } from '@/utils/storage';
import { TOKEN } from "./enums";
import { computed, getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();

const token = ref(Storage.get(TOKEN));

const getTouristToken = () => {
      HttpManager.getTouristToken().then((res) => {
        const { code, data } = res as ResponseBody;
        if (code === 200 && data && data.token) {
          Storage.set(TOKEN, data.token);
          token.value = data.token;
        } else {
          (proxy as any).$message({
            message: '网络错误，请重试',
            type: 'error',
          });
        }
      });
    }

if(!token.value){
  getTouristToken();
}
</script>

