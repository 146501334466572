import axios from "axios";
import {Storage} from '@/utils/storage';
import { ElMessage } from 'element-plus'

import qs from "qs";
import { TOKEN } from "@/enums";

const BASE_URL =  process.env.NODE_HOST;

// axios.defaults.timeout = 5000; // 超时时间设置
// axios.defaults.withCredentials = true; // true允许跨域
// axios.defaults.baseURL = BASE_URL;
// Content-Type 响应头
// axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";


axios.create({
	timeout: 10000,
	// baseURL: import.meta.env.VITE_APP_API_URL
	// baseURL: 'http://47.115.45.139:9527'+'api'
})


axios.interceptors.request.use(
	config => {
const token = Storage.get(TOKEN) || '';
		// return config
		// 设置请求头
		config.headers = {
			"Content-Type": "application/x-www-form-urlencoded",
			'isTourist': token?(token.endsWith('-tourist') ? 1 : 0) : 1, // 0-已登录、1-游客
			"token": token
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      const {code,msg} = response.data;
      if(code==499 && msg==='游客登录有误'){
          ElMessage({
            message:  "登录已超时",
            type: "error",
          });
          Storage.set(TOKEN, '');
           setTimeout(() => {
            // router.replace({
            //   path: "/",
            // });
            window.location.href = '/';
          }, 1000);
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
   (error) => {
    // console.log('error.response.status',error.response.status)
    if (error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // case 401:
        //   router.replace({
        //     path: "/",
        //     query: {
        //       // redirect: router.currentRoute.fullPath
        //     },
        //   });
        //   break;
        case 403:
          // console.log('管理员权限已修改请重新登录')
          // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
          ElMessage({
            message:  "登录已超时",
            type: "error",
          });
          Storage.set(TOKEN, '');
          setTimeout(() => {
            // router.replace({
            //   path: "/",
            // });
            window.location.href = '/';
          }, 1000);
          break;
        // 500服务器错误
        case 500:
        ElMessage({
                message: "网络错误，请重试",
                type: "error",
              });
          break;
        // 404请求不存在
        case 404:
          // console.log('请求页面飞到火星去了')
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

export function getBaseURL() {
  return BASE_URL;
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url, params?: object) {
  return new Promise((resolve, reject) => {
    axios.get(url, params).then(
      (response) => resolve(response.data),
      (error) => reject(error)
    );
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function post(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, qs.stringify(data)).then(
      (response) => resolve(response.data),
      (error) => reject(error)
    );
  });
}


/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function deletes(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.delete(url, data).then(
      (response) => resolve(response.data),
      (error) => reject(error)
    );
  });
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data).then(
      (response) => resolve(response.data),
      (error) => reject(error)
    );
  });
}
